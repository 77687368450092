import React, { useEffect } from "react";
import MainLayout from "../layout/MainLayout";
import { Link } from "react-router-dom";
import MansetBlogs from "../components/MansetBlogs";
import Quesiton from "../components/Quesiton";
import Order from "../components/Order";

function Service2() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let title = "Mobil tətbiqlərin hazırlanması, Mobil tətbiqlərin yaradılması, andorid app, ios app";
  return (
    <MainLayout>
      <div className="line_wrap">
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
      </div>

      <section className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt">
                  <h1 title={title}>Mobil Tətbiqlər</h1>
                  <span>
                    <Link to="/">MySoft</Link>
                    <i className="bi bi-arrow-right"></i>Mobil Tətbiqlər
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img src="assets/img/app.png" alt="Mobil tətbiqlərin hazırlanması, Mobil Tətbiqin Sizə Və Təşkilatınıza Qazandırdığı Əsas
                  Üstünlüklər" />
                <h3 title={title}>
                  <i>
                    <img
                      src="assets/img/icons/service-details-icon.png"
                      alt="Mobil tətbiqlərin hazırlanması, Mobil Tətbiqin Sizə Və Təşkilatınıza Qazandırdığı Əsas
                      Üstünlüklər"
                    />
                  </i>
                  Mobil Tətbiqin Sizə Və Təşkilatınıza Qazandırdığı Əsas
                  Üstünlüklər
                </h3>
                <p title={title}>
                  Günümüz iş dünyasında mobil tətbiqlər, şirkətlər üçün əsaslı
                  və sürətli bir dəyişiklik gətirməkdədir. İş dünyasının bu
                  dönəmdə mobil tətbiqlərə keçid etməsinin səbəbləri və bu
                  tətbiqlərin üstünlükləri ətraflı bir şəkildə müzakirə olunur.
                </p>
                <p title={title}>
                  Mobil tətbiqlər, şirkətlərin əməliyyatlarını effektiv şəkildə
                  idarə etməyə kömək edir. İşçilər və idarəçilər real vaxtda
                  məlumatlara çatmağa imkan verən tətbiqlər vasitəsilə daha
                  sürətli qərarlar verə bilirlər.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <MansetBlogs />
              <Quesiton/>
            </div>
          </div>
          <div className="service-items sec-mar-top">
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title={title}>
                      <span>01</span>Mobil Tərəfdaşlıq və Əlaqə
                    </h4>
                    <p title={title}>
                      Mobil tətbiq, şirkətin müştəriləri və tərəfdaşları ilə
                      daha yaxın əlaqə qurmağa kömək edir. Müştərilər tətbiq
                      vasitəsilə sifarişlərini yerinə yetirə, xidmətlərlə əlaqə
                      saxlaya və şirkətin son yeniliklərindən xəbərdar olmağa
                      nail olurlar.
                    </p>
                    <p title={title}>
                      Mobil tətbiqlər, idarəçilərə real vaxtda iş prosesləri
                      haqqında məlumat təqdim edir. Bu, idarəçilərin
                      strategiyalarını tənzimləmələrinə və şirkətin
                      nailiyyətlərinə daha sürətli reaksiya vermələrinə kömək
                      edir.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src="assets/img/app1.jpg" alt="Mobil tətbiqlərin hazırlanması, Mobil Tərəfdaşlıq və Əlaqə" />
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img src="assets/img/app2.png" alt="Mobil tətbiqlərin hazırlanması, İş Sahəsində Effektivlik" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title={title}>
                      <span>02</span>İş Sahəsində Effektivlik
                    </h4>
                    <p title={title}>
                      Mobil tətbiqlər, işçiləri uzaqdan işləmə imkanı təmin
                      edir. Bu, iş tələbatlarını yerinə yetirmək üçün işçilərə
                      müstəqil və müstəqil iş etmək imkanı yaradır. Uzaq iş
                      rejimi, işçilərə iş və həyat balansını daha effektiv
                      şəkildə idarə etməyə imkan verir.
                    </p>
                    <p title={title}>
                      Həmçinin, şirkətlərin əməliyyatlarını daha effektiv və
                      sürətli hala gətirməyə kömək edən prosesləri
                      avtomatlaşdırmağa imkan verir. Məsələn, texnologiya
                      vasitəsilə sifarişlərin işlənməsi, ödənişlərin izlənməsi
                      və digər keyfiyyətli əməliyyatlar, şirkətin keyfiyyət və
                      effektivliyini artır.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title={title}>
                      <span>03</span>Marketinq və Brend İnkişafı
                    </h4>
                    <p title={title}>
                      Web saytı, brendin təqdim etdiyi məhsullar və xidmətlər
                      haqqında ətraflı məlumat təqdim etməklə müştərilərə doğru
                      seçim etmələrində kömək edir. Məhsul və xidmətlərə dair
                      görsəl və mətn təqdimatlar, müştərilərin öz ehtiyaclarına
                      daha yaxşı cavab verə bilər.
                    </p>
                    <p title={title}>
                      Əlaqə məlumatları, müştərilər üçün şirkətlə əlaqə
                      saxlamağın və ya məlumat almağın ən asan yollarından
                      biridir. Bu, saytın ünvanı, əlaqə nömrəsi, e-poçt ünvanı
                      və ya müştəri dəstəyi ilə əlaqə məlumatlarını içərir.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src="assets/img/app3.png" alt="Mobil tətbiqlərin hazırlanması, Marketinq və Brend İnkişafı" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Order/>
    </MainLayout>
  );
}

export default Service2;
