import React, { useEffect } from "react";
import MainLayout from "../layout/MainLayout";
import { Link } from "react-router-dom";
import MansetBlogs from "../components/MansetBlogs";
import Quesiton from "../components/Quesiton";
import Order from "../components/Order";

function Service3() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let title = "SEO Xidmətləri, SEO xidmətləri ilə trafiki artır";
  let alt = "SEO Xidmətləri, GOOGLE-da önə çıxmaq";
  return (
    <MainLayout>
      <div className="line_wrap">
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
      </div>

      <section className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt">
                  <h1 title={title}>SEO Xidmətləri</h1>
                  <span>
                    <Link to="/">MySoft</Link>
                    <i className="bi bi-arrow-right"></i>SEO Xidmətləri
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img src="assets/img/seo.png" alt={alt} />
                <h3 title={title}>
                  <i>
                    <img
                      src="assets/img/icons/service-details-icon.png"
                      alt={alt}
                    />
                  </i>
                  Veb Saytınızın Axtarış Motorlarında Yüksək Sıralanması üçün
                  Təcrübəli Strategiyalar.
                </h3>
                <p title={title}>
                  SEO xidmətləri, bir veb saytının axtarış motorlarında yüksək
                  pozisiyada görünməsi və müştəri trafiyini artırmaq üçün tətbiq
                  edilən strateji və tədbirlər bütününü ifadə edir. Bu strateji,
                  bir şirkətin onlayn tərəqqiyatını təmin etmək və müştərilərlə
                  əlaqə qurmağa nail olmaq üçün ən əhəmiyyətli alətlərdən
                  biridir. İşlərin veb saytları, axtarış motorlarında yaxşı
                  sıralanmağa nail olaraq öz böhranlarını və müştəri bazasını
                  genişləndirməyə çalışır.
                </p>
                <p title={title}>
                  SEO tətbiq etmək, müştərilərə veb saytınızı tapmaqda kömək
                  edir və onlara ən mühüm məlumatları daha asanlıqla əldə etməyə
                  imkan verir. Axtarış motorları, istifadəçilərin axtarış
                  sorğularına cavab verən veb saytları üst sıralarda göstərməyə
                  nail olan alqoritmik formullar tətbiq edir.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <MansetBlogs />
              <Quesiton/>
            </div>
          </div>
          <div className="service-items sec-mar-top">
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title={title}>
                      <span>01</span>Gözlənilən Trafik Artımı
                    </h4>
                    <p title={title}>
                      SEO, veb saytınıza düzgün məzmun, efektiv açar sözlər və
                      sənaye standartlarına uyğun meta etiketlər əlavə etmək
                      üçün strategiyalar tətbiq edir. Bu məzmunlar,
                      istifadəçilər üçün ən əhəmiyyətli və maraqli məlumatları
                      təmin etməklə yanaşı, axtarış motorlarına veb saytınızın
                      nə haqda olduğu barədə doğru məlumat verməyə kömək edir.
                    </p>
                    <p title={title}>
                      SEO xidmətləri, veb saytınıza linklər əlavə edərək və
                      sənaye əhəmiyyətli və müstəqil veb saytlarından
                      backlinklər alaraq saytınızın nəzərdə tutulmuş axtarış
                      sorğularında daha yüksək sıralanmasına nail olmağa
                      çalışır. Backlinklər, axtarış motorları üçün saytınızın
                      maraqli və güvənilir olduğunu göstərmək üçün ən
                      əhəmiyyətli faktorlardan biridir.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src="assets/img/seo1.png" alt={alt} />
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img src="assets/img/seo2.png" alt={alt} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title={title}>
                      <span>02</span>Müştəri Təcrübəsinin Yaxşılaşdırılması
                    </h4>
                    <p title={title}>
                      SEO, müştərilər üçün daha rahat və sürətli bir təcrübə
                      təmin etmək üçün veb saytınızın arayüzünü və
                      funksiyalarını tənzimləməyə kömək edir. Mobil uyğunluq və
                      sürətli yüklənmə, axtarış motorlarının veb saytınıza daha
                      yüksək reytinq verərək müştəri təcrübəsini
                      yaxşılaşdırmasına kömək edir.
                    </p>
                    <p title={title}>
                      SEO tədbirləri, brendinizin onlayn sərfəliliyini artırmağa
                      və müştərilər tərəfindən daha çox tanınmağa imkan verir.
                      Gözlənilən nəticələrdə yüksək sıralanmaq, müştərilərə
                      brendinizin maraqli, təhlükəsiz və güvənilir olduğu
                      şəkildə təsir etməyə imkan verir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title={title}>
                      <span>03</span>Marketinq və Brend İnkişafı
                    </h4>
                    <p title={title}>
                      SEO investisiyaları, reklam və digər marketinq
                      tədbirlərinə nisbətən daha bağlamalı və uzun müddətli bir
                      strategiya olaraq qiymətləndirilir. Bu, müştərilər
                      tərəfindən təsadüfi olaraq deyil, daimi müştəri əlaqələri
                      qurmağa nail olmağınızı təmin edir.
                    </p>
                    <p title={title}>
                      SEO tətbiqi, sizi sənayenizdə rəqabət üstünlüyü əldə
                      etməyə kömək edir. Gözlənilən axtarış nəticələrində yüksək
                      sıralanmaq, müştərilər üçün sizi seçmələrini asanlaşdırır
                      və rəqabətçilərinizlə mübarizədə üstünlük qazanmanıza
                      imkan verir.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src="assets/img/seo3.png" alt={alt} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Order/>
    </MainLayout>
  );
}

export default Service3;
