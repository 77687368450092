import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <footer>
        <div className="container">
          <div className="footer-top">
            <div className="row">
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <Link to="/">
                      <img src="/assets/img/MySoft.svg" alt="MySoft" />
                    </Link>
                  </div>
                  <p>MySoft ilə Bacarmayacağınız heç bir şey qalmayacaq</p>
                  <ul className="social-media-icons">
                    <li>
                      <a href="https://www.facebook.com/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.twitter.com/">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.pinterest.com/">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Siz istəyin biz yaradaq</h4>
                  <ul className="footer-menu">
                    <li>
                      <Link to="/saytlarin-hazirlanmasi">
                        Saytların Hazırlanması
                      </Link>
                    </li>
                    <li>
                      <Link to="/mobil-tetbiqler">Mobil Tətbiqlər</Link>
                    </li>
                    <li>
                      <Link to="/seo-xidmetleri">SEO Xidmətləri</Link>
                    </li>
                    <li>
                      <Link to="/server-texniki-destek">
                        Server & Texniki Dəstək
                      </Link>
                    </li>
                    <li>
                      <Link to="/IT-destek">IT Dəstək</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Son Proqramlar</h4>
                  <ul className="footer-menu">
                    <li>
                      <a href="https://vertuaz.com/" target="_blank" rel="noopener noreferrer">VERTU</a>
                    </li>
                    <li>
                      <a href="https://my-group.ae/" target="_blank" rel="noopener noreferrer">MyGroup</a>
                    </li>
                    <li>
                      <a href="https://myshops.az/" target="_blank" rel="noopener noreferrer">MyShops</a>
                    </li>
                    <li>
                      <a href="https://mynumber.az/" target="_blank" rel="noopener noreferrer">MyNumber</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-lg-3 col-xl-3">
                <div className="footer-widget">
                  <h4>Əlaqə</h4>
                  <div className="number">
                    <div className="num-icon">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="phone">
                      <Link to="tel:+994512070910">+994 51 207 09 10</Link>
                    </div>
                  </div>
                  <div className="office-mail">
                    <div className="mail-icon">
                      <i className="far fa-envelope"></i>
                    </div>
                    <div className="email">
                      <Link to="tell:info@mysoft.az">
                        <span
                          className="__cf_email__"
                          data-cfemail="721b1c141d32170a131f021e175c111d1f"
                        >
                          info@mysoft.az
                        </span>
                      </Link>
                      <Link to="tell:ceo@mysof.az">
                        <span
                          className="__cf_email__"
                          data-cfemail="345d5a525b74474144445b46401a575b59"
                        >
                          ceo@mysoft.az
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="address">
                    <div className="address-icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <p>AF Business Center, 7-ci mərtəbə</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-12 col-md-4 col-lg-4 col-xl-5">
                <div className="copy-txt">
                  <span>
                    Copyright 2024 <Link to="/">MySoft</Link> | Design By{" "}
                    <Link to="/">MySoft</Link>
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-8 col-lg-8 col-xl-7">
                <ul className="footer-bottom-menu">
                  {/* <li><a href="test">Privacy Policy</a></li> */}
                  {/* <li><a href="test">Terms of Use</a></li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
