import React, { useEffect } from "react";
import { useState } from "react";
import MainLayout from "../layout/MainLayout";
import BlogData from "../components/BlogData";
import { Link } from "react-router-dom";
import Quesiton from "../components/Quesiton";
import { useParams } from "react-router-dom";

function BlogDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [blogDB] = useState(BlogData);
  const mansetBlogData = blogDB.filter((blog) => blog.manset === 1);
  const params = useParams();
  const blog = blogDB.filter((blog) => blog.id === parseInt(params.blogID));
  return (
    <MainLayout>
      <div className="line_wrap">
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
      </div>

      <section className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt">
                  <h1>Bloq</h1>
                  <span>
                    <Link to="/">MySoft</Link>
                    <i className="bi bi-arrow-right"></i>Bloq
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-details-area sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="blog-details-content">
                <h3>
                  {blog[0].title}
                </h3>
                <div className="author-date layout2">
                  <span>{blog[0].date}</span>
                </div>
                <div className="details-thumb">
                  <img src={`/${blog[0].image}`} alt={blog[0].title} />
                </div>
                <p>
                {blog[0].text}
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              {/* <div className="sidebar-widget">
                <div className="widget-search">
                  <form action="#" method="post">
                    <input
                      type="text"
                      name="search"
                      placeholder="Search Here"
                    />
                    <button type="submit">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div>
              </div> */}
              <div className="sidebar-widget">
                <h4>Son bloqlar</h4>
                {mansetBlogData && mansetBlogData.map(blog => (
                <div key={blog.id} className="recent-post">
                  <div className="recent-thumb">
                    <Link  to={`/bloq/${blog.id}`}>
                      <img src={`/${blog.image}`} alt={blog.title} />
                    </Link>
                  </div>
                  <div className="recent-post-cnt">
                    <span>{blog.date}</span>
                    <h5>
                      <Link to={`/bloq/${blog.id}`}>
                      {blog.title}
                      </Link>
                    </h5>
                  </div>
                </div>
                ))}
              </div>
              <Quesiton/>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}

export default BlogDetail;
