import React, { useEffect } from "react";
import MainLayout from "../layout/MainLayout";
import { Link } from "react-router-dom";
import MansetBlogs from "../components/MansetBlogs";
import Quesiton from "../components/Quesiton";
import Order from "../components/Order";

function Service4() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  let title = "Server xidmətlərinin göstərilməsi, Texniki dəstəyin göstərilməsi, Mobil Tətbiqlərə Texniki Dəsdəyin Göstərilməsi, Veb saytlara Texniki Dəsdəyin Göstərilməsi.";
  let alt = "Server xidmətləri, Texniki Dəstək xidmətləri";
  return (
    <MainLayout>
      <div className="line_wrap">
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
      </div>

      <section className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt">
                  <h1 title={title}>Server & Texniki Dəstək</h1>
                  <span>
                    <Link to="/">MySoft</Link>
                    <i className="bi bi-arrow-right"></i>Server & Texniki Dəstək
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img src="assets/img/server.png" alt={alt} />
                <h3 title={title}>
                  <i>
                    <img
                      src="assets/img/icons/service-details-icon.png"
                      alt={alt}
                    />
                  </i>
                  Server İnfrastrukturu və Texniki Dəstək
                </h3>
                <p title={title}>
                  Server infrastrukturu və texniki dəstək, bir şirkətin
                  informasiya texnologiyalarının effektiv və müstəqim şəkildə
                  işləməsini təmin etmək üçün əsas rol oynayan kritik
                  komponentlərdən ibarətdir. İT bölməsi, serverlər və şəbəkə
                  təchizatının düzgün funksionallaşması üçün optimal
                  tənzimləmələr tətbiq edir, bu da şirkətin iş proseslərinin
                  sürətli və təhlükəsiz şəkildə həyata keçirilməsini təmin edir.
                </p>
                <p title={title}>
                  Server infrastrukturu, məlumatların effektiv idarə olunması və
                  təhlükəsizliyi üçün əhəmiyyətli bir təminat təşkil edir və İT
                  bölməsi, bu infrastrukturu monitorinq edir və sürətli
                  texnologiya inkişafı ilə möhkəmləndirir. Texniki dəstək,
                  işçilərin kompüter və proqram tətbiqləri ilə əlaqəli
                  məsələlərini həll etmək üçün təşkil edilmiş bir sistemdir və
                  İT bölməsi, işçilərə sürətli və dəqiq texniki yardım təmin
                  edir, bu da iş proseslərinin sürətli və problematsız şəkildə
                  davam etməsinə yardım edir.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <MansetBlogs />
              <Quesiton/>
            </div>
          </div>
          <div className="service-items sec-mar-top">
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title={title}>
                      <span>01</span> Server İnfrastrukturu
                    </h4>
                    <p title={title}>
                      Server infrastrukturu, bir şirkətin informasiya
                      texnologiyalarının əsasını təşkil edir. Bu infrastruktur,
                      məlumatların depolanması, işlənilməsi və paylaşılmasını
                      təmin etməklə yanaşı, şirkətin ümumilikdə işləməsini
                      dəstəkləyən əhəmiyyətli bir komponentdir. Server
                      infrastrukturu, müxtəlif serverlərin və şəbəkə
                      cihazlarının effektiv şəkildə əlaqələndirilməsi və idarə
                      olunması yolu ilə işləyir.
                    </p>
                    <p title={title}>
                      İT bölməsi, şirkətin server infrastrukturunu inkişaf
                      etdirmək və optimal performans təmin etmək üçün sürətli
                      dəyişən texnologiya tələblərinə cavab verir. Bu,
                      serverlərin sürətli işləməsini, məlumatların effektiv
                      şəkildə qoşulmasını və depolanmasını, müxtəlif layihələr
                      və tədbirlər üçün əlaqəli server resurslarının effektiv
                      idarə olunmasını əhatə edir.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src="assets/img/server1.png" alt={alt} />
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img src="assets/img/server2.png" alt={alt} />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title={title}>
                      <span>02</span>Texniki Dəstək
                    </h4>
                    <p title={title}>
                      Texniki dəstək, bir şirkətin işçilərinin kompüter və
                      informasiya texnologiyası ilə əlaqəli məsələlərinin
                      sürətli və effektiv şəkildə həll olunmasını təmin edir. İT
                      bölməsi, işçilərə kompüter, proqram və şəbəkə təchizatında
                      texniki dəstək təqdim edir. Bu, işçilərin problemləri
                      tez-tez həll etməsinə və işlərini narahatlıq yaşamadan
                      davam etdirməsinə kömək edir.
                    </p>
                    <p title={title}>
                      Texniki dəstək, işçilərə qoşulmağa və sistemlərdə sürətli
                      işləməyə nail olmağa kömək edir. İT bölməsi, kompüterlər
                      və proqram tətbiqləri üçün müstəqil tədbirlər və
                      upgradelər təyin edir, bu da işçilərin tələblərinə cavab
                      verir və texnologiya inkişafını təmin edir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title={title}>
                      <span>03</span>Məlumat Təhlükəsizliyi
                    </h4>
                    <p title={title}>
                      Məlumat təhlükəsizliyi, bir şirkətin əsas varlığını təşkil
                      edən məlumatların müstəqil və təhlükəsiz şəkildə
                      qorunmasını ifadə edir. İT bölməsi, məlumat
                      təhlükəsizliyini təmin etmək və müvafiq tədbirlərlə
                      riskləri minimalizə etmək üçün müxtəlif texnologiya və
                      protokolları tətbiq edir.
                    </p>
                    <p title={title}>
                      Məlumat təhlükəsizliyi, məlumatların müvafiq təhlükəsizlik
                      standartlarına uyğun şəkildə saxlanılmasını,
                      göndərilməsini və işlənilməsini əhatə edir. İT bölməsi,
                      təhlükəsiz şəbəkələr və server infrastrukturu təmin edir,
                      məlumatların şifrələnməsini və müdafiə olunmasını həyata
                      keçirir.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src="assets/img/server3.png" alt={alt} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Order/>
    </MainLayout>
  );
}

export default Service4;
