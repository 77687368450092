
const BlogData = [
  {
    "id": 11,
    "manset":1,
    "image": "assets/img/blog/blog-vertu.jpg",
    "title": "Vertuaz.com saytı artıq İstifadəçilərinin rahatlığını nəzərə alaraq İstifadəyə verildi!",
    "text": "Vertu telefonları incə sənətkarlığın ən müasir texnologiya ilə üst-üstə düşdüyü lüksün son ifadəsidir. Hər bir telefon İngiltərədə yüksək keyfiyyətli materiallardan, o cümlədən praktiki olaraq cızıqlara davamlı sapfir kristalından, möhkəm 5-ci dərəcəli titandan və dəbdəbəli əl ilə tikilmiş dəridən istifadə edilməklə diqqətlə hazırlanır. Bu elementlər yalnız hər bir cihazı vizual olaraq heyrətamiz edir, həm də bənzərsiz davamlılıq və uzunömürlülük təmin edir. Hər bir Vertu telefonunun qəlbində hətta ən tələbkar tapşırıqlar üçün qüsursuz və həssas performans təmin edən güclü Qualcomm prosessoru dayanır. Safir kristalla qorunan displey müstəsna aydınlıq və canlılıq təqdim edərək, hər bir təsvir və videonu canlandırır. Qabaqcıl kamera sistemi istənilən işıqlandırma şəraitində mükəmməl fotoşəkillər çəkmək üçün hazırlanmışdır ki, bu da onu həm gündəlik anlar, həm də qeyri-adi təcrübələr üçün ideal edir. Batareya bütün gün istifadə üçün nəzərdə tutulmuşdur, sürətli doldurma imkanları heç vaxt enerjisiz qalmamağınızı təmin edir. Təcrübəni tamamlamaq üçün hər bir Vertu telefonunda fərdiləşdirilmiş yardım və dünya üzrə unikal təcrübələrə eksklüziv çıxış təklif edən 24/7 konsyerj xidməti daxildir. Vertu telefonları sadəcə cihazlar deyil, onlar statusun, zərifliyin və misilsiz lüksün simvoludur.",
    "date": "2024-08-29"
  },
    {
      "id": 1,
      "manset":1,
      "image": "assets/img/blog/poco_x6.jpg",
      "title": "POCO X6 və POCO X6 Pro təqdim olunub - QİYMƏTİ",
      "text": "POCO brendi POCO M6 Pro 4G smartfonu ilə yanaşı POCO X6 smartfon seriyasını da təqdim edib. Sözügedən seriyaya iki model daxil olub. POCO X6 və X6 Pro. Hər iki smartfon xarici görünüşcə və texniki göstəricilər baxımından demək olar eynidirlər. Xarici görünüş məsələsində onlar arxa kameranın dizaynı baxımından bir qədər fərqlənirlər. Texniki göstəricilər səviyyəsində də bəzi fərqliliklər var. Beləliklə hər iki smartfon 120 Hz-lik təsvir yenilənməsinə sahib 2772x1220 piksel keyfiyyətli 6.67 düym AMOLED ekranla təchiz olunub. Ekranların maksimal parlaqlıq səviyyələri 1800 nit təşkil edir.",
      "date": "2024-01-10"
    },
    {
      "id": 2,
      "image": "assets/img/blog/power_banki.jpg",
      "manset":1,
      "title": "Xiaomi kompakt ölçülü yeni power bankını satışa çıxarıb - QİYMƏTİ",
      "text": "Xiaomi öz yeni power bankını satışa çıxarıb. Satışa çıxmış yeni power bank Xiaomi-nin daha öncəki power bank modellərindən bir qədər fərqlənir. Belə ki, yeni power bank öz kompakt ölçüsü və kiçik həcmi ilə seçilir. Sözügedən yeni power bank-ın ölçüləri 113 х 53 х 10 mm, çəkisi isə 93 qramm təşkil edir. Bu cür kompakt ölçüyə sahib olması onu gün ərzində gəzdirməyi daha da rahat edir. Yeni power bank sadəcə bir ədəd USB Type-C girişinə sahibdir. Onun həcmi isə 5000 mAh təşkil edir. Power bank-ın özü 18 vatt-a kimi çıxış gücünə sahib enerji mənbəyi vasitəsilə şarj olunur və onun 100% enerji yığması üçün 2 saat vaxt lazımdır. Sözügedən power bank artıq Çin bazarında 18$ qiymətində satışa çıxıb. Onun digər regionlarda satılıb-satılmayacağı isə bilinmir.",
      "date": "2024-01-11"
    },
    {
      "id": 3,
      "manset":0,
      "image": "assets/img/blog/orion_moon1.jpg",
      "title": "Amerikalı astronavtların Aya geri dönüşləri 1 illik təxirə salınıb",
      "text": "NASA “Artemis 2” və “Artemis 3” Ay missiyalarını 1 illik təxirə salıb. Qeyd etmək lazımdır ki, “Artemis 2” missiyası çərçivəsində amerikalı astronavtların Ay ətrafı uçuşları reallaşdırılacaq. Bu missiyanın daha öncə bu ilin sonunda keçirilməsi planlaşdırılırdı. Lakin NASA “Artemis 2” missiyasını 2025-ci ilin sentyabr ayına kimi təxirə salıb. “Artemis 3” missiyası çərçivəsində isə astronavtlar artıq Ayın Cənub qütbünə ayaq basacaqlar. Daha öncə “Artemis 3” missiyasının məhz 2025-ci ildə reallaşdırılması planlaşdırılırdı. Lakin NASA-nın yeni qrafikinə əsasən “Artemis 3” 2026-ın sentyabr ayı üçün planlaşdırılır. Ayın orbitində yerləşdirilməsi nəzərdə tutulan Gateway adlı kosmik stansiyaya astronavtların uçuşlarını nəzərdə tutan “Artemis 4” missiyası isə 2028-ci ildə baş tutmalıdır. NASA sözügedən Ay missiyalarının təxirə salınması səbəbi kimi missiyalarda iştirak edəcək astronavtların təhlükəsizliklərinin təmin olunmasını gətirib. Belə ki, bu missiyalarda iştirak edəcək Orion adlı kosmik gəminin təhlükəsizlik sistemlərinin testləri zamanı müəyyən problemlər ortaya çıxıb. Həmin problemlərin aradan qaldırılması üçün isə NASA mühəndislərinə əlavə vaxt lazım olacaq.",
      "date": "2024-01-11"
    },
    {
      "id": 4,
      "manset":0,
      "image": "assets/img/blog/Snapdragon-7-Gen-3.jpg",
      "title": "Qualcomm-un növbəti Snapdragon 7 Plus prosessoru olduqca performanslı olacaq",
      "text": "Qualcomm şirkəti hal-hazırda Snapdragon 7 seriyasının yeni prosessoru üzərində çalışır və onun sözügedən seriyanın ən performanslı prosessoru olmağına dair bütün şansları var. Digital Chat Station ləqəbli məşhur insayderin sözlərinə əsasən növbəti Snapdragon 7 Plus prosessoru seriyanın ən performanslı prosessoru olacaq. Çox güman ki, hazırlanan bu yeni prosessor Snapdragon 7+ Gen 3 adını əldə etsin. Bununla yanaşı məlumatda qeyd edilib ki, Snapdragon 7+ Gen 3 prosessoru özündə Snapdragon 8 Gen 3-ün nüvələrini birləşdirəcək.",
      "date": "2024-01-11"
    },
  ];

export default BlogData