import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Subscribe from "../components/Subscribe";

function MainLayout({ children }) {
  return (
    <div>
      <Navbar />
      <main className="creasoft-wrap">
        <div>{children}</div>
        <Subscribe />
      </main>
      <div className="cursor"></div>
      <Footer />
    </div>
  );
}

export default MainLayout;
