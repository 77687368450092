import React, { useEffect } from "react";
import MainLayout from "../layout/MainLayout";
import { Link } from "react-router-dom";
import MansetBlogs from "../components/MansetBlogs";
import Quesiton from "../components/Quesiton";
import Order from "../components/Order";

function Service5() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainLayout>
      <div className="line_wrap">
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
      </div>

      <section className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt">
                  <h1>IT dəstək</h1>
                  <span>
                    <Link to="/">MySoft</Link>
                    <i className="bi bi-arrow-right"></i>IT dəstək
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img src="assets/img/itsupport.png" alt="ss" />
                <h3>
                  <i>
                    <img
                      src="assets/img/icons/service-details-icon.png"
                      alt="ss"
                    />
                  </i>
                  İnformasiya Texnologiyaları Stratejisi və İdarəetməsinin Baş
                  Rolu: Şirkətin İT Bölməsinin Əhəmiyyəti
                </h3>
                <p>
                  İş dünyasında informasiya texnologiyalarının rolü daha da
                  artmaqdadır və bir şirkətin müvəffəqiyyətli fəaliyyəti üçün İT
                  bölməsi əsas bir strateji elementi kimi qarşılanmağa ehtiyaç
                  göstərir. İT bölməsi, şirkətin kompüter infrastrukturunun
                  effektiv idarə edilməsində, məlumatların təhlükəsizliyini
                  təmin etmədə, proqram tətbiqlərinin inkişaf etdirilməsində və
                  iş tələblərinə cavab vermədə kritik rol oynayır.
                </p>
                <p>
                  İT bölməsi, iş təşkilatının digər bölmələri ilə səmərəli bir
                  əməkdaşlıq təmin edir və təchizatı və texnologiya
                  infrastrukturunu tənzimləməkdə liderliyini möhkəmləndirir.
                  Digər bölmələrlə tərəfdaşlıq edərək, şirkətinin məqsədlərinə
                  nail olmağında katalizator rolunu oynayır və inkişaf etmiş
                  informasiya texnologiyaları vasitəsilə şirkətinin rəqabət
                  gücünü artırmağa nail olur. İT bölməsi, sürətli dəyişikliklərə
                  cavab vermək və texnologiya innovasiyalarını şirkət
                  stratejiyasına inteqrasiya etmək üçün düzgün strateji və
                  taktikaları tətbiq edir.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <MansetBlogs />
              <Quesiton/>
            </div>
          </div>
          <div className="service-items sec-mar-top">
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>01</span>Təhlükəsizlik
                    </h4>
                    <p>
                      Təhlükəsizlik, bir şirkətin informasiya texnologiyalarını
                      müdafiə etmək və məlumatların qorunması üçün tətbiq edilən
                      tədbirləri əhatə edir. Təhlükəsizlik stratejiyası,
                      məxfilik, müstəqil təhlükəsizlik tədbirləri və şirkətin
                      daxili təhlükəsizlik standartları kimi ən son texnologiya
                      tədbirlərini içərir. Bu tədbirlər, məlumatların yetki
                      olunmayan tərəflər tərəfindən əldə edilməsini, müstəqil
                      təhlükəsizlik vəziyyətlərini müdafiə etməsini və mövcud
                      məlumatların təhlükəsizliyini təmin etməsini özündə əks
                      etdirir. Bu stratejiya, firevoll, antivirüs proqramları,
                      məlumat şifrələnməsi, nəzarət və şirkət içi təlimatlar
                      kimi tətbiqləri əhatə edir.
                    </p>
                    <p>
                      Təhlükəsizlik stratejiyasının effektiv şəkildə icrası,
                      şirkətin məlumatlarını potensial təhlükələrdən qoruyaraq
                      müştərilərin və iş tərəfdaşlarının etibarını qazanmağa və
                      mövcud müştərilərini qorumağa nail olmağa kömək edir.
                      Ayrıca, bu tədbirlər, qanuni və regulativ tələblərə riayət
                      etmək üçün də vacibdir və müstəqil təhlükəsizlik auditi və
                      monitorinq proseslərinin müəyyən edilməsinə kömək edir.
                      Təhlükəsizlik stratejiyasının nəzərdə tutulması, şirkətin
                      informasiya texnologiyalarının ümumi effektivliyini
                      artıraraq potensial risklərə qarşı daha müstəqil və
                      ətraflı bir qorunma tədbiri təmin etməyə nail olur.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src="assets/img/itsupport1.png" alt="ss" />
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img src="assets/img/itsupport2.png" alt="ss" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>02</span>İnformasiya Texnologiyalarında Təchizat
                      Təminatı
                    </h4>
                    <p>
                      İT bölməsi, iş tələblərinə uyğun kompüterlər, serverlər,
                      şəbəkə cihazları, proqram təminatları və digər informasiya
                      texnologiyası vasitələrini seçməkdə və təmin etməkdə
                      mütəxəssis təcrübəyə sahibdir. Təchizatın keyfiyyətli və
                      effektiv şəkildə seçilməsi, iş proseslərinin sürətli və
                      düzgün işləməsinə imkan verir, bu isə şirkətin ümumilikdə
                      performansını artırır.
                    </p>
                    <p>
                      Ən son tədbirlər və innovativ təcrübə, İT bölməsinin
                      müstəqil təchizat təminatı tədbirlərini optimal səviyyədə
                      qorumağını və inkişaf etdirməsini təmin edir. Bu, şirkətin
                      müasir informasiya texnologiyası standartlarına
                      uyğunlaşmasına və müştəri tələblərinə cavab verə bilən
                      daimi bir texnologiya aləti parkının yaradılmasına nail
                      olmağa kömək edir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4>
                      <span>03</span>Yeni Texnologiyaların İstifadəsi
                    </h4>
                    <p>
                      Yeni texnologiyaların qoşulması, şirkətin iş proseslərini
                      sürətləmdirmək, məlumatları daha effektiv idarə etmək və
                      müştəri təcrübəsini yaxşılaşdırmaq üçün dərin təsir
                      göstərə bilər. İT bölməsi, bu texnologiyaları müvafiq
                      təhlükəsizlik tədbirləri ilə birləşdirərək, şirkətin iş
                      operativliyini artırmağa və rəqabət avantajı yaratmağa
                      nail olur.
                    </p>
                    <p>
                      Yeni texnologiyaların tətbiqi, İT bölməsinin texnologiya
                      inkişafı üçün müstəqil təlimatlar və monitorinq
                      proseslərini təyin etməsini tələb edir. Bu, şirkətin
                      sürətli dəyişən iş mühiti içində fəal olaraq qalmağını və
                      müştərilərinin tələblərinə cavab verə bilməsini təmin
                      edir.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src="assets/img/itsupport3.png" alt="ss" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Order/>
    </MainLayout>
  );
}

export default Service5;
