import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import './index.css';
import reportWebVitals from './reportWebVitals';
import Main from './pages/Main';
import Service1 from './pages/Service1';
import Service2 from './pages/Service2';
import Service3 from './pages/Service3';
import Service4 from './pages/Service4';
import Service5 from './pages/Service5';
import Blogs from './pages/Blogs';
import AboutUs from './pages/AboutUs';
import BlogDetail from './pages/BlogDetail';

const router = createBrowserRouter([
  {
    path: "/",
    element: <div><Main/></div>,
  },
  {
    path: "/saytlarin-hazirlanmasi",
    element: <div><Service1/></div>,
  },
  {
    path: "/mobil-tetbiqler",
    element: <div><Service2/></div>,
  },
  {
    path: "/seo-xidmetleri",
    element: <div><Service3/></div>,
  },
  {
    path: "/server-texniki-destek",
    element: <div><Service4/></div>,
  },
  {
    path: "/IT-destek",
    element: <div><Service5/></div>,
  },
  {
    path: "/bloq",
    element: <div><Blogs/></div>,
  },
  {
    path: "/bloq/:blogID",
    element: <div><BlogDetail/></div>,
  },
  {
    path: "/haqqimizda",
    element: <div><AboutUs/></div>,
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
