import React from 'react'

function Quesiton() {
  return (
    <div>
        <div className="sidebar-banner">
                <img src="/assets/img/widget-banner-bg.jpg" alt="ss" />
                <div className="banner-inner">
                  <h3>
                  Sualınız var? <span>Əlaqə.</span>
                    <img
                      className="angle"
                      src="/assets/img/arrow-angle.png"
                      alt="Reklam"
                    />
                  </h3>
                  <a href="tel:+994512070910">+994 51 207 09 10</a>
                </div>
              </div>
    </div>
  )
}

export default Quesiton