import React from "react";
import { useState } from "react";
import axios from 'axios';

function Order() {
  const [inputs, setInputs] = useState({});

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  const handleSubmit = async  (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://mysoft.az/bck/", inputs);
      console.log(response.data);
      document.getElementById("orderForm").reset();
   } catch (error) {
      console.error("Sorğu zamanı xəta:", error);
   }
  }
  return (
    <div>
      <section className="contact-area sec-mar">
        <div className="container">
          
          <div className="getin-touch">
            <div className="row">
              <div className="col-md-6 col-lg-7">
                <div
                  className="contact-form wow animate fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                  style={{visibility: 'visible', animationDuration: '1500ms', animationDelay:'200ms'}}
                >
                  <h3>Sİz İstəyİn bİz yaradaq</h3>
                  <form onSubmit={handleSubmit} id="orderForm">
                    <div className="row">
                      <div className="col-12">
                        <input
                          type="text"
                          name="name"
                          placeholder="Ad, Soyad"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="email"
                          name="email"
                          placeholder="E-poçt"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          name="phone"
                          placeholder="Telefon"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-md-12">
                        <input
                          type="text"
                          name="subject"
                          placeholder="Mövzu"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          name="message"
                          cols="30"
                          rows="10"
                          placeholder="Sifarişiniz"
                          onChange={handleChange}
                        ></textarea>
                        <input type="submit" value="Göndər" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className="col-md-6 col-lg-5 wow animate fadeInUp"
                data-wow-delay="300ms"
                data-wow-duration="1500ms"
                style={{visibility: 'visible', animationDuration: '1500ms', animationDelay: '300ms'}}
              >
                <div className="call-banner">
                  <img src="assets/img/call-center.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Order;
