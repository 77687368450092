import React, { useEffect } from "react";
import MainLayout from "../layout/MainLayout";
import { Link } from "react-router-dom";
import MansetBlogs from "../components/MansetBlogs";
import Order from "../components/Order";
import Quesiton from "../components/Quesiton";

function Service1() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainLayout>
      <div className="line_wrap">
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
      </div>

      <section className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt">
                  <h1>Saytların hazırlanması</h1>
                  <span>
                    <Link to="/">MySoft</Link>
                    <i className="bi bi-arrow-right"></i>Saytların Hazırlanması
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service-details sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="service-details-content">
                <img src="assets/img/creator.png" alt="Saytların Hazırlanması" title="Saytların hazırlanması, veb saytların yığılması" />
                <h3 title="Saytların hazırlanması, veb saytların yığılması">
                  <i>
                    <img
                      src="assets/img/icons/service-details-icon.png"
                      alt="Saytların Hazırlanması,  Web Saytının Sizə Və Təşkilatınıza Qazandırdığı Əsas
                      Üstünlüklər"
                      title="Saytların hazırlanması, veb saytların yığılması"
                    />
                  </i>
                  Web Saytının Sizə Və Təşkilatınıza Qazandırdığı Əsas
                  Üstünlüklər
                </h3>
                <p title="Saytların hazırlanması, veb saytların yığılması">
                  İnternetin sürətlə yayılması və texnologiyaların inkişafı, web
                  saytlarının hər bir şəxsin və təşkilatın onlayn varlığı üçün
                  əlverişli olaraq gözlənilən rolu daha da gücləndirmişdir.
                </p>
                <p title="Saytların hazırlanması, veb saytların yaradılması">
                  İnsanlar artıq hər hansı bir məlumatı əldə etmək və ya bir
                  məhsulu almaq üçün internetə müraciət edərək, müstəqil axtarış
                  edirlər. Bir web saytı təqdim etdiyi məlumatlar, görsəllər və
                  interaktiv funksiyalar ilə müştərilərin təcrübəsini
                  zənginləşdirir. Onlayn təcrübə, müştərilər üçün daha əyləncəli
                  və maraqli hala gətirir.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <MansetBlogs />
              <Quesiton/>
            </div>
          </div>
          <div className="service-items sec-mar-top">
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title="Rəqabət Üstünlüyü, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting">
                      <span>01</span> Rəqabət Üstünlüyü
                    </h4>
                    <p title="Rəqabət Üstünlüyü, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting">
                    Rəqəmsal dövrün sürətlə inkişaf etdiyi və texnologiyanın hər bir aspektinin iş dünyasına təsir etdiyi indiki vaxtda 
                    biznesin onlayn mövcudluğu qaçılmaz olub. Potensial müştərilərə çatmaq, brend şüurunu artırmaq və rəqabət üstünlüyü əldə etmək üçün bir çox biznesin web saytı olmalıdır.
                    </p>
                    <p title="Rəqabət Üstünlüyü, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting">
                      E-ticarət platformalarının inkişafı və onlayn satışın
                      artması, bir web saytının təşkilat üçün sürətli və
                      effektiv bir satış meydana gətirən bir mexanizmə kimi
                      fəaliyyət göstərməsinə imkan verir. Bu, müştərilərin
                      onlayn alış-veriş imkanlarından asanlıqla istifadə etməsi
                      və şirkətin satışlarına olan nailiyyətini artırmaq üçün
                      ideal bir yoldur.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src="assets/img/creator1.png" alt="ss" />
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6 or-2">
                  <div className="single-details-thumb">
                    <img src="assets/img/blog/thumb-2.jpg" alt="Brendin Gücləndirilməsi və Tanıması, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting" title="Brendin Gücləndirilməsi və Tanıması, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title="Brendin Gücləndirilməsi və Tanıması, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting">
                      <span>02</span>Brendin Gücləndirilməsi və Tanıması
                    </h4>
                    <p title="Brendin Gücləndirilməsi və Tanıması, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting">
                      Web saytının dizaynı, bir brendin görünüşünü təyin edən ən
                      əsas elementlərdən biridir. Gözəl və effektiv dizayn,
                      müştərilərə və tərəfdaşlara brendin professional və üstün
                      keyfiyyətini nümayiş etdirir. Müstəsna və maraqli bir
                      dizayn, brendin insanlar arasında diqqəti cəlb edir və onu
                      əhatəli və əlverişli təqdim edir.
                    </p>
                    <p title="Brendin Gücləndirilməsi və Tanıması, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting">
                      Web saytı, brendin müstəsna əsas məzmununu təqdim etmək və
                      digər təşkilatlar arasında ayırd edici olmaq üçün əla bir
                      platformadır. Məhsullar, xidmətlər və şirkətin missiyası
                      haqqında ətraflı və özəl məlumatlar, brendin müştərilər
                      üçün nə qədər müstəqil və önəmli olmağı, ona uyğun olaraq
                      təqdim edilir.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="service-details-item">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-details">
                    <h4 title="Ətraflı Məlumat və Təhlilat Təminatı və Tanıması, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting">
                      <span>03</span>Ətraflı Məlumat və Təhlilat Təminatı
                    </h4>
                    <p title="Ətraflı Məlumat və Təhlilat Təminatı və Tanıması, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting">
                      Web saytı, brendin təqdim etdiyi məhsullar və xidmətlər
                      haqqında ətraflı məlumat təqdim etməklə müştərilərə doğru
                      seçim etmələrində kömək edir. Məhsul və xidmətlərə dair
                      görsəl və mətn təqdimatlar, müştərilərin öz ehtiyaclarına
                      daha yaxşı cavab verə bilər.
                    </p>
                    <p title="Ətraflı Məlumat və Təhlilat Təminatı və Tanıması, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting">
                      Əlaqə məlumatları, müştərilər üçün şirkətlə əlaqə
                      saxlamağın və ya məlumat almağın ən asan yollarından
                      biridir. Buna saytın ünvanı, əlaqə nömrəsi, e-poçt ünvanı
                      və ya müştəri dəstəyi ilə əlaqə kimi məlumatlar daxildir.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-details-thumb">
                    <img src="assets/img/blog/thumb-3.jpg" alt="Ətraflı Məlumat və Təhlilat Təminatı və Tanıması, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting" title="Ətraflı Məlumat və Təhlilat Təminatı və Tanıması, Saytların hazırlanması, veb saytların yaradılması, Domain, Hosting"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Order/>

    </MainLayout>
  );
}

export default Service1;
