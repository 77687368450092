import React from 'react'
import { useState } from "react";
import { Link } from "react-router-dom";
import BlogData from "../components/BlogData";

function MansetBlogs() {
    const [blogDB] = useState(BlogData);
    const mansetBlogData = blogDB.filter(blog => blog.manset === 1);
  return (
    <div>
        <div className="sidebar-widget">
                <h4>Trend bloqlar</h4>
                {mansetBlogData.map(blog => (
                <div key={blog.id} className="recent-post">
                  <div className="recent-thumb">
                    <Link  to={`/bloq/${blog.id}`}>
                      <img src={blog.image} alt="ss" />
                    </Link>
                  </div>
                  <div className="recent-post-cnt">
                    <span>{blog.date}</span>
                    <h5>
                      <Link to={`/bloq/${blog.id}`}>
                      {blog.title}
                      </Link>
                    </h5>
                  </div>
                </div>
                ))}
              </div>
    </div>
  )
}

export default MansetBlogs