import React, { useEffect } from "react";
import { useState } from "react";
import MainLayout from "../layout/MainLayout";
import BlogData from "../components/BlogData";
import { Link } from "react-router-dom";


function Blogs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
    const [blogDB] = useState(BlogData);
    const mansetBlogData = blogDB.filter(blog => blog.manset === 1);
  return (
    <MainLayout>
      <div className="line_wrap">
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
      </div>

      <section className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt">
                  <h1>Bloq</h1>
                  <span>
                    <Link to="/">MySoft</Link>
                    <i className="bi bi-arrow-right"></i>Bloq
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog-grid sec-mar-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              {/* <div className="sidebar-widget">
                <div className="widget-search">
                  <form action="#" method="post">
                    <input
                      type="text"
                      name="search"
                      placeholder="Bloq axtar"
                    />
                    <button type="submit">
                      <i className="bi bi-search"></i>
                    </button>
                  </form>
                </div>
              </div> */}
              
              <div className="sidebar-widget">
                <h4>Son bloqlar</h4>
                {mansetBlogData.map(blog => (
                <div key={blog.id} className="recent-post">
                  <div className="recent-thumb">
                    <Link  to={`/bloq/${blog.id}`}>
                      <img src={blog.image} alt="ss" />
                    </Link>
                  </div>
                  <div className="recent-post-cnt">
                    <span>{blog.date}</span>
                    <h5>
                      <Link to={`/bloq/${blog.id}`}>
                      {blog.title}
                      </Link>
                    </h5>
                  </div>
                </div>
                ))}
              </div>
              
                {/* <div className="sidebar-banner">
                  <img src="assets/img/widget-banner-bg.jpg" alt="ss" />
                  <div className="banner-inner">
                    <h3>
                      REKLAM ÜÇÜN <span>ƏLAQƏ.</span>
                      <img
                        className="angle"
                        src="assets/img/arrow-angle.png"
                        alt="ss"
                      />
                    </h3>
                    <Link to="tel:11231231234">+994 51 207 02</Link>
                  </div>
                </div> */}
            </div>
            <div className="col-lg-8">
              <div className="blog-item-grid">
                <div className="row g-4">
                {blogDB.map(blog => (
                  <div key={blog.id} className="col-md-6">
                    <div className="single-blog">
                      <div className="blog-thumb">
                        <Link to={`/bloq/${blog.id}`}>
                          <img src={blog.image} alt="ss" />
                        </Link>
                        <div className="tag">
                          <span>MySoft</span>
                        </div>
                      </div>
                      <div className="blog-inner">
                        <div className="author-date">
                          <span>{blog.date}</span>
                        </div>
                        <h4>
                          <Link to={`/bloq/${blog.id}`}>
                          {blog.title}
                          </Link>
                        </h4>
                      </div>
                    </div>
                  </div>
                ))}  
                  
                </div>
              </div>
              {/* <div className="load-more">
                <ul className="paginations">
                  <li>
                    <Link to="">
                      <i className="fas fa-long-arrow-alt-left"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="">01</Link>
                  </li>
                  <li className="active">
                    <Link to="">02</Link>
                  </li>
                  <li>
                    <Link to="">03</Link>
                  </li>
                  <li>
                    <Link to="">04</Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
}

export default Blogs;
