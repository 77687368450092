import React, { useEffect } from "react";
import { Autoplay,Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import {  Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import MainLayout from "../layout/MainLayout";
import { Link } from "react-router-dom";
import LatestBlog from "../components/LatestBlog";
import Order from "../components/Order";

function Main() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainLayout>
      <div className="line_wrap">
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
        <div className="line_item"></div>
      </div>

      <section className="hero-area">
        <div className="ken-burns-slideshow">
          <img src="assets/img/main.jpg" alt="ss" />
        </div>
        <div className="verticale-social">
          <ul className="vertical-media">
            <li>
              <a href="https://www.facebook.com/">Facebook</a>
            </li>
            <li>
              <a href="https://www.instagram.com/">Instagram</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/">Linkedin</a>
            </li>
          </ul>
        </div>
        <div className="hero-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content">
                  <h1>
                    Texno & İnkİşaf<span>MySoft.</span>
                  </h1>
                  <p>
                    MYSOFT, texnologiyaların və inkişafın ən son
                    nailiyyətlərindən istifadə edərək müştərilərinin web
                    saytlarını hazırlayır və tətbiqlərini yaradır. Şirkətimiz,
                    sizi müstəqil və effektiv IT həlləri ilə təmin edir,
                    müştərilərə innovativ çözümlər təqdim edərək
                    texnologiyalarda ən son inkişafı təklif edir.
                  </p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1"></div>
                      <div className="line-2"></div>
                      <Link to="/haqqimizda">Biz kimik?</Link>
                    </div>
                    {/* <div className="cmn-btn layout-two">
                      <div className="line-1"></div>
                      <div className="line-2"></div>
                      <a href="project.html">Nələr yaratmışıq?</a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
        </div>
      </section>
                    <Order/>
      <section className="services-area sec-mar">
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="sec-title">
              <h2>Xidmətlərimiz</h2>
              <p>
                MySoft sizin üçün keyfiyyətli xidmətlərini təqdim etməyə daima hazırdır.İşinizi peşəkar və təcrübəli komandamıza həvalə edə bilərsiniz.
              </p>
            </div>
          </div>
          <Swiper
            modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
            loop={true}
                autoplay={{
                    delay: 1800,
                    disableOnInteraction: false,
                }}
            spaceBetween={50}
            breakpoints={{
              850: {
                slidesPerView: 3,
              },
              500: {
                slidesPerView: 2,
              },
              1: {
                slidesPerView:1,
              }
            }}
          >
            <SwiperSlide>
              <div className="single-service">
                <span>01</span>
                <div className="icon">
                  <img src="assets/img/icons/service-icon-1.png" alt="test" />
                </div>
                <h4>Saytların Hazırlanması</h4>
                <p>
                Web Saytının Sizə Və Təşkilatınıza Qazandırdığı Əsas Üstünlüklər...
                </p>
                <div className="read-btn">
                  <Link to="/saytlarin-hazirlanmasi">Keçid et</Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="single-service">
                <span>02</span>
                <div className="icon">
                  <img src="assets/img/icons/service-icon-2.png" alt="test" />
                </div>
                <h4>Mobil Tətbiqlər</h4>
                <p>
                  Mobil Tətbiqin Sizə Və Təşkilatınıza Qazandırdığı Əsas Üstünlüklər
                </p>
                <div className="read-btn">
                  <Link to="/mobil-tetbiqler">Keçid et</Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="single-service">
                <span>03</span>
                <div className="icon">
                  <img src="assets/img/icons/service-icon-3.png" alt="test" />
                </div>
                <h4>SEO Xidmətləri</h4>
                <p>
                  Veb Saytınızın Axtarış Motorlarında Yüksək Sıralanması üçün Təcrübəli Strategiyalar.
                </p>
                <div className="read-btn">
                  <Link to="/seo-xidmetleri">Keçid et</Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="single-service">
                <span>04</span>
                <div className="icon">
                  <img src="assets/img/icons/service-icon-3.png" alt="test" />
                </div>
                <h4>Server & Texniki Dəstək</h4>
                <p>
                  Server İnfrastrukturu və Texniki Dəstək
                </p>
                <div className="read-btn">
                  <Link to="/server-texniki-destek">Keçid et</Link>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="single-service">
                <span>05</span>
                <div className="icon">
                  <img src="assets/img/icons/service-icon-3.png" alt="test" />
                </div>
                <h4>IT Dəstək</h4>
                <p>
                  İnformasiya Texnologiyaları Stratejisi və İdarəetməsinin Baş Rolu: Şirkətin İT Bölməsinin Əhəmiyyəti
                </p>
                <div className="read-btn">
                  <Link to="/IT-destek">Keçid et</Link>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/* <section className="about-area sec-mar-bottom">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 or-2 wow animate fadeIn"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="sec-title layout2">
                <span>Get To Know</span>
                <h2>About Us</h2>
              </div>
              <div className="about-left">
                <h3>We do design, code & develop Software finally launch.</h3>
                <p>
                  Integer purus odio, placerat nec rhoncus in, ullamcorper nec
                  dolor. className onlin aptent taciti sociosqu ad litora
                  torquent per conubia nostra, per inceptos only himenaeos.
                  Praesent nec neque at dolor venenatis consectetur eu quis ex.
                  the Donec lacinia placerat felis non aliquam.
                </p>
                <div className="company-since">
                  <div className="company-logo">
                    <img src="assets/img/logo-dark.svg" alt="test" />
                  </div>
                  <strong>#1</strong>
                  <h4>
                    Best Creative IT Agency And Solutions{" "}
                    <span>Since 2005.</span>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 or-1 wow animate fadeIn"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="about-right">
                <div className="banner-1">
                  <img src="assets/img/about-baner-1.jpg" alt="test" />
                </div>
                <div className="banner-2">
                  <img src="assets/img/about-baner-2.jpg" alt="test" />
                  <div className="banner2-inner">
                    <div className="play">
                      <a
                        className="video-popup"
                        href="https://www.youtube.com/watch?v=0O2aH4XLbto"
                      >
                        <i className="fas fa-play"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="features-area">
        <div className="container">
          <div
            className="title-wrap  wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="sec-title white">
              <h2>Göstəricilərimiz</h2>
              <p>
                MySoft şirkəti sizin üçün
              </p>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src="assets/img/icons/feature-icon-1.png" alt="test" />
                  </div>
                  <span className="counter">150</span>
                  <sup>+</sup>
                  <h4>Tamamlanmış Proyekt</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src="assets/img/icons/feature-icon-2.png" alt="test" />
                  </div>
                  <span className="counter">250</span>
                  <sup>+</sup>
                  <h4>Məmnun Müştəri</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src="assets/img/icons/feature-icon-3.png" alt="test" />
                  </div>
                  <span className="counter">50</span>
                  <sup>+</sup>
                  <h4>Ekspert Komanda</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="800ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img src="assets/img/icons/feature-icon-4.png" alt="test" />
                  </div>
                  <span className="counter">30</span>
                  <sup>+</sup>
                  <h4>Sertifikat</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Case Study</span>
              <h2>Project</h2>
              <p>
                Curabitur sed facilisis erat. Vestibulum pharetra eros eget
                fringilla porttitor. on Duis a orci nunc. Suspendisse ac
                convallis sapien, quis commodo libero.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <ul className="isotope-menu">
                <li className="active" data-filter="*">
                  All
                </li>
                <li data-filter=".ui">UI/UX</li>
                <li data-filter=".web">Web Design</li>
                <li data-filter=".developing">Developing</li>
                <li data-filter=".graphic">Graphic Design</li>
              </ul>
            </div>
          </div>
          <div className="row g-4 project-items">
            <div className="col-md-6 col-lg-4 single-item graphic ui">
              <div className="item-img">
                <a href="project.html">
                  <img src="assets/img/project/project-1.jpg" alt="test" />
                </a>
              </div>
              <div className="item-inner-cnt">
                <span>Software</span>
                <h4>Desktop Mockup</h4>
                <div className="view-btn">
                  <a href="project-details.html">view details</a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 single-item developing web">
              <div className="item-img">
                <a href="project.html">
                  <img src="assets/img/project/project-2.jpg" alt="test" />
                </a>
              </div>
              <div className="item-inner-cnt">
                <span>Template</span>
                <h4>Creative Agency</h4>
                <div className="view-btn">
                  <a href="project-details.html">view details</a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 single-item developing">
              <div className="item-img">
                <a href="project.html">
                  <img src="assets/img/project/project-3.jpg" alt="test" />
                </a>
              </div>
              <div className="item-inner-cnt">
                <span>App</span>
                <h4>Mobile Crypto Wallet</h4>
                <div className="view-btn">
                  <a href="project-details.html">view details</a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 single-item graphic">
              <div className="item-img">
                <a href="project.html">
                  <img src="assets/img/project/project-4.jpg" alt="test" />
                </a>
              </div>
              <div className="item-inner-cnt">
                <span>UI Kit</span>
                <h4>E-Shop Ecommerce</h4>
                <div className="view-btn">
                  <a href="project-details.html">view details</a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 single-item ui">
              <div className="item-img">
                <a href="project.html">
                  <img src="assets/img/project/project-5.jpg" alt="test" />
                </a>
              </div>
              <div className="item-inner-cnt">
                <span>Graphic</span>
                <h4>Art Deco Cocktails</h4>
                <div className="view-btn">
                  <a href="project-details.html">view details</a>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 single-item web">
              <div className="item-img">
                <a href="project.html">
                  <img src="assets/img/project/project-6.jpg" alt="test" />
                </a>
              </div>
              <div className="item-inner-cnt">
                <span>3D Design</span>
                <h4>Low poly Base mesh</h4>
                <div className="view-btn">
                  <a href="project-details.html">view details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="our-partner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="sec-title white layout2">
                <span>Satisfied Client</span>
                <h2>Our Partner</h2>
                <div className="-partnerslider-navigator">
                  <div className="swiper-button-prev-c">
                    <i className="bi bi-chevron-left"></i>
                  </div>
                  <div className="swiper-button-next-c">
                    <i className="bi bi-chevron-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="swiper partner-slider">
                <div className="swiper-wrapper">
                  <div className="swiper-slide">
                    <div className="single-partner">
                      <img src="assets/img/partner/partner-1.png" alt="test" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="single-partner">
                      <img src="assets/img/partner/partner-2.png" alt="test" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="single-partner">
                      <img src="assets/img/partner/partner-3.png" alt="test" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="single-partner">
                      <img src="assets/img/partner/partner-4.png" alt="test" />
                    </div>
                  </div>
                  <div className="swiper-slide">
                    <div className="single-partner">
                      <img src="assets/img/partner/partner-5.png" alt="test" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="priceing-plan sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
              <span>Getting Start</span>
              <h2>Pricing Plan</h2>
              <p>
                Curabitur sed facilisis erat. Vestibulum pharetra eros eget
                fringilla porttitor. on Duis a orci nunc. Suspendisse ac
                convallis sapien, quis commodo libero.
              </p>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <div className="price-table-tab">
                <ul className="nav nav-pills" id="pills-tab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Pay Monthly
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Pay Yearly
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tab-content" id="pills-tabContent">
            <div
              className="tab-pane fade active show"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              <div className="row g-4">
                <div
                  className="col-md-6 col-lg-4 wow animate fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  <div className="price-box">
                    <h3>Small Business</h3>
                    <span>Single Business</span>
                    <strong>
                      $15.99<sub>/Per Month</sub>
                    </strong>
                    <ul className="item-list">
                      <li>
                        <i className="bi bi-check"></i>10 Pages Responsive
                        Website
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5PPC Campaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>10 SEO Keyword
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5 Facebook Camplaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>2 Video Camplaigns
                      </li>
                    </ul>
                    <div className="price-btn">
                      <div className="line-1"></div>
                      <div className="line-2"></div>
                      <a href="contact.html">Pay Now</a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 wow animate fadeInUp"
                  data-wow-delay="400ms"
                  data-wow-duration="1500ms"
                >
                  <div className="price-box">
                    <h3>Professional</h3>
                    <span>Small team</span>
                    <strong>
                      $99.99<sub>/Per Month</sub>
                    </strong>
                    <ul className="item-list">
                      <li>
                        <i className="bi bi-check"></i>10 Pages Responsive
                        Website
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5PPC Campaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>10 SEO Keyword
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5 Facebook Camplaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>2 Video Camplaigns
                      </li>
                    </ul>
                    <div className="price-btn">
                      <div className="line-1"></div>
                      <div className="line-2"></div>
                      <a href="contact.html">Pay Now</a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 wow animate fadeInUp"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div className="price-box">
                    <h3>Enterprice</h3>
                    <span>Large Team</span>
                    <strong>
                      $120.99<sub>/Per Month</sub>
                    </strong>
                    <ul className="item-list">
                      <li>
                        <i className="bi bi-check"></i>10 Pages Responsive
                        Website
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5PPC Campaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>10 SEO Keyword
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5 Facebook Camplaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>2 Video Camplaigns
                      </li>
                    </ul>
                    <div className="price-btn">
                      <div className="line-1"></div>
                      <div className="line-2"></div>
                      <a href="contact.html">Pay Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
            >
              <div className="row g-4">
                <div
                  className="col-md-6 col-lg-4 wow animate fadeInUp"
                  data-wow-delay="200ms"
                  data-wow-duration="1500ms"
                >
                  <div className="price-box">
                    <h3>Small Business</h3>
                    <span>Single Business</span>
                    <strong>
                      $35.99<sub>/Per Year</sub>
                    </strong>
                    <ul className="item-list">
                      <li>
                        <i className="bi bi-check"></i>10 Pages Responsive
                        Website
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5PPC Campaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>10 SEO Keyword
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5 Facebook Camplaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>2 Video Camplaigns
                      </li>
                    </ul>
                    <div className="price-btn">
                      <div className="line-1"></div>
                      <div className="line-2"></div>
                      <a href="contact.html">Pay Now</a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 wow animate fadeInUp"
                  data-wow-delay="400ms"
                  data-wow-duration="1500ms"
                >
                  <div className="price-box">
                    <h3>Professional</h3>
                    <span>Small team</span>
                    <strong>
                      $199.99<sub>/Per Year</sub>
                    </strong>
                    <ul className="item-list">
                      <li>
                        <i className="bi bi-check"></i>10 Pages Responsive
                        Website
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5PPC Campaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>10 SEO Keyword
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5 Facebook Camplaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>2 Video Camplaigns
                      </li>
                    </ul>
                    <div className="price-btn">
                      <div className="line-1"></div>
                      <div className="line-2"></div>
                      <a href="contact.html">Pay Now</a>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 wow animate fadeInUp"
                  data-wow-delay="600ms"
                  data-wow-duration="1500ms"
                >
                  <div className="price-box">
                    <h3>Enterprice</h3>
                    <span>Large Team</span>
                    <strong>
                      $220.99<sub>/Per Year</sub>
                    </strong>
                    <ul className="item-list">
                      <li>
                        <i className="bi bi-check"></i>10 Pages Responsive
                        Website
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5PPC Campaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>10 SEO Keyword
                      </li>
                      <li>
                        <i className="bi bi-check"></i>5 Facebook Camplaigns
                      </li>
                      <li>
                        <i className="bi bi-check"></i>2 Video Camplaigns
                      </li>
                    </ul>
                    <div className="price-btn">
                      <div className="line-1"></div>
                      <div className="line-2"></div>
                      <a href="contact.html">Pay Now</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="testimonial-area">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Testimonial</span>
              <h2>Client Say About Us</h2>
              <p>
                Curabitur sed facilisis erat. Vestibulum pharetra eros eget
                fringilla porttitor. on Duis a orci nunc. Suspendisse ac
                convallis sapien, quis commodo libero.
              </p>
            </div>
          </div>
          <div className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              <div className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right"></i>
                  </div>
                  <h5>Martha Maldonado</h5>
                  <span>Executive Chairman</span>
                  <div className="stars">
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                  </div>
                  <p>
                    Integer purus odio, placerat nec rhoncus in, ullamcorper nec
                    dolor. ani aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per sonic himenaeos. Praesent nec neque at
                    dolor venenatis consectetur europ Donec lacinia placerat
                    felis non aliquam.
                  </p>
                  <div className="reviewer">
                    <img src="assets/img/reivewer.jpg" alt="test" />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right"></i>
                  </div>
                  <h5>Jesmin Korac</h5>
                  <span>Director</span>
                  <div className="stars">
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                  </div>
                  <p>
                    Integer purus odio, placerat nec rhoncus in, ullamcorper nec
                    dolor. ani aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per sonic himenaeos. Praesent nec neque at
                    dolor venenatis consectetur europ Donec lacinia placerat
                    felis non aliquam.
                  </p>
                  <div className="reviewer">
                    <img src="assets/img/reivewer-1.jpg" alt="test" />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right"></i>
                  </div>
                  <h5>Martha Maldonado</h5>
                  <span>Executive Chairman</span>
                  <div className="stars">
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                  </div>
                  <p>
                    Integer purus odio, placerat nec rhoncus in, ullamcorper nec
                    dolor. ani aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per sonic himenaeos. Praesent nec neque at
                    dolor venenatis consectetur europ Donec lacinia placerat
                    felis non aliquam.
                  </p>
                  <div className="reviewer">
                    <img src="assets/img/reivewer.jpg" alt="test" />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right"></i>
                  </div>
                  <h5>Jesmin korac</h5>
                  <span>Director</span>
                  <div className="stars">
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                  </div>
                  <p>
                    Integer purus odio, placerat nec rhoncus in, ullamcorper nec
                    dolor. ani aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per sonic himenaeos. Praesent nec neque at
                    dolor venenatis consectetur europ Donec lacinia placerat
                    felis non aliquam.
                  </p>
                  <div className="reviewer">
                    <img src="assets/img/reivewer-1.jpg" alt="test" />
                  </div>
                </div>
              </div>
              <div className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right"></i>
                  </div>
                  <h5>Martha Maldonado</h5>
                  <span>Executive Chairman</span>
                  <div className="stars">
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                    <a href="test">
                      <i className="fas fa-star"></i>
                    </a>
                  </div>
                  <p>
                    Integer purus odio, placerat nec rhoncus in, ullamcorper nec
                    dolor. ani aptent taciti sociosqu ad litora torquent per
                    conubia nostra, per sonic himenaeos. Praesent nec neque at
                    dolor venenatis consectetur europ Donec lacinia placerat
                    felis non aliquam.
                  </p>
                  <div className="reviewer">
                    <img src="assets/img/reivewer.jpg" alt="test" />
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-pagination d-md-none d-md-block"></div>
          </div>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
        </div>
      </section>

      <section className="our-team sec-mar">
        <div className="container">
          <div
            className="title-wrap  wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="sec-title">
              <span>Our Team</span>
              <h2>Meet Our Team</h2>
              <p>
                Curabitur sed facilisis erat. Vestibulum pharetra eros eget
                fringilla porttitor. on Duis a orci nunc. Suspendisse ac
                convallis sapien, quis commodo libero.
              </p>
            </div>
          </div>
          <div className="swiper team-slider">
            <div className="swiper-wrapper">
              <div
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="single-team">
                  <div className="member-img">
                    <img src="assets/img/team/team-1.jpg" alt="test" />
                    <svg
                      width="185"
                      height="299"
                      viewBox="0 0 167 269"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.25412 0.814453C1.68125 2.62384 0 5.61553 0 8.99991V269H167C167 269 47 269 66.5 112.171C75.5581 39.3209 20.2679 8.22409 4.25412 0.814453Z"
                        fill="#191A1C"
                      />
                    </svg>
                    <ul className="team-social">
                      <li>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.twitter.com/">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.whatsapp.com/">
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="team-inner">
                    <h4>Thoren Okendhild</h4>
                    <span>Executive Chairman</span>
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className="single-team">
                  <div className="member-img">
                    <img src="assets/img/team/team-2.jpg" alt="test" />
                    <svg
                      width="185"
                      height="299"
                      viewBox="0 0 167 269"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.25412 0.814453C1.68125 2.62384 0 5.61553 0 8.99991V269H167C167 269 47 269 66.5 112.171C75.5581 39.3209 20.2679 8.22409 4.25412 0.814453Z"
                        fill="#191A1C"
                      />
                    </svg>
                    <ul className="team-social">
                      <li>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.twitter.com/">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.whatsapp.com/">
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="team-inner">
                    <h4>Lincoln Anthony</h4>
                    <span>Software Engeenier</span>
                  </div>
                </div>
              </div>
              <div
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className="single-team">
                  <div className="member-img">
                    <img src="assets/img/team/team-3.jpg" alt="test" />
                    <svg
                      width="185"
                      height="299"
                      viewBox="0 0 167 269"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.25412 0.814453C1.68125 2.62384 0 5.61553 0 8.99991V269H167C167 269 47 269 66.5 112.171C75.5581 39.3209 20.2679 8.22409 4.25412 0.814453Z"
                        fill="#191A1C"
                      />
                    </svg>
                    <ul className="team-social">
                      <li>
                        <a href="https://www.instagram.com/">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.twitter.com/">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.whatsapp.com/">
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="team-inner">
                    <h4>Adrian Eodri</h4>
                    <span>UI/UX Designer</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section> */}

      <LatestBlog/>

    </MainLayout>
  );
}

export default Main;
