import React from "react";
import { useState } from "react";
import axios from 'axios';
function Subscribe() {
  const [inputs, setInputs] = useState({});

  const handleChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };
  const handleSubmit = async  (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("https://mysoft.az/bck/subscribe/", inputs);
      console.log(response.data);
      document.getElementById("subsForm").reset();
   } catch (error) {
      console.error("Sorğu zamanı xəta:", error);
   }
  }
  return (
    <div>
      <section className="subscribe-newsletter sec-mar-top">
        <div className="container">
          <div className="news-letter-content">
            <div className="row align-items-center">
              <div
                className="col-lg-6 wow animate fadeInLeft"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="subscribe-cnt">
                  <span>Yeniliklər üçün</span>
                  <h3>Abunə Olun</h3>
                  <strong>MYSOFT</strong>
                </div>
              </div>
              <div
                className="col-lg-6 wow animate fadeInRight"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className="subscribe-form">
                  <form onSubmit={handleSubmit} id="subsForm">
                    <input
                      type="email"
                      name="email"
                      placeholder="E-poçunu daxil et"
                      onChange={handleChange}
                    />
                    <input type="submit" value="Qoşul" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Subscribe;
