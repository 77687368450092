import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import BlogData from "../components/BlogData";

function LatestBlog() {
  const [blogDB] = useState(BlogData.slice(0, 3));
  return (
    <div>
      <section className="blog-area">
        <div className="container">
          <div
            className="title-wrap -6 wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="sec-title">
              <h2>Son bloqlar</h2>
              <p>
                Texnologiya aləmində baş verən son hadisələri daima yenilənən MySoft bloq bölməmizdə izləyə bilərsiniz.
              </p>
            </div>
          </div>
          <div className="row gy-4">
          {blogDB.map((blog) => (
            <div
              className="col-md-6 col-lg-4 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="single-blog">
                <div className="blog-thumb">
                  <Link to={`/bloq/${blog.id}`}>
                    <img src={`/${blog.image}`} alt="test" />
                  </Link>
                  <div className="tag">
                    <span>MySoft</span>
                  </div>
                </div>
                <div className="blog-inner">
                  <div className="author-date">
                    <span>{blog.date}</span>
                  </div>
                  <h4>
                    <Link to={`/bloq/${blog.id}`}>
                      {blog.title}
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default LatestBlog;
