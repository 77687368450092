import React from 'react'
import { useEffect } from 'react';
import $ from 'jquery';
import { Link } from "react-router-dom";
function Navbar() {
    useEffect(() => {
        
        $(document).mousemove(function(e) {
            $(".cursor").css({
                "transform": "translate3d(calc(" + e.clientX + "px - 50%), calc(" + e.clientY + "px - 50%), 0)"
            });
        });
            $("a").hover(
                function () {
                    $(".cursor").css({});
                },
                function () {
                    $(".cursor2").css({ "background-color": "#fff" });
                }
            );
            $(window).on("load", function () {
                $(".preloader").delay(1600).fadeOut("slow");
            });
            // const currentLocation = location.href;
            // for (let i = 0; i < menuLength; i++) {
            //     if (menuItem[i].href === currentLocation) {
            //         menuItem[i].className = "active";
            //     }
            // }
            $(window).on("scroll", function () {
                if ($(this).scrollTop() > 200) {
                    $(".position_top").addClass("sticky");
                } else {
                    $(".position_top").removeClass("sticky");
                }
            });
            $(".cross-btn").on("click", function (event) {
                $(".main-nav").toggleClass("slidenav");
            });
            $(".remove").on("click", function (event) {
                $(".main-nav").removeClass("slidenav");
            });
            $(".main-nav .bi").on("click", function (event) {
                var $fl = $(this);
                $(this).parent().siblings().find(".sub-menu").slideUp();
                $(this).parent().siblings().find(".bi").addClass("bi-chevron-down");
                if ($fl.hasClass("bi-chevron-down")) {
                    $fl.removeClass("bi-chevron-down").addClass("bi-chevron-up");
                } else {
                    $fl.removeClass("bi-chevron-up").addClass("bi-chevron-down");
                }
                $fl.next(".sub-menu").slideToggle();
            });
            $(".tab-link").on("click", function (event) {
                $(".tab-link").removeClass("active");
                $(this).addClass("active");
            });
            // $(".counter").counterUp({ delay: 10, time: 1000 });
            // $("ul.isotope-menu li").on("click", function () {
            //     $("ul.isotope-menu li").removeClass("active");
            //     $(this).addClass("active");
            //     var selector = $(this).attr("data-filter");
            //     $(".project-items").isotope({ filter: selector, animationOptions: { duration: 750, easing: "linear", queue: false } });
            //     return false;
            // });
            // $(".video-popup").magnificPopup({ disableOn: 700, type: "iframe", mainClass: "mfp-fade", removalDelay: 160, preloader: false, fixedContentPos: false });
            // $(window).on("load", function () {
            //     new WOW().init();
            //     window.wow = new WOW({ boxClass: "wow", animateClass: "animated", offset: 0, mobile: true, live: true, offset: 100 });
            //     window.wow.init();
            // });
        
      }, []);
  return (
    <header className="header-area position_top">
<div className="site-logo">
<div className="logo">
<Link to="/"><img style={{width:'250px'}} src="/assets/img/MySoft.svg" alt="MySoft"/></Link>
</div>
</div>
<div className="main-menu">
<nav className="main-nav">
<div className="mobile-menu-logo">
<Link to="/"><img src="/assets/img/MySoftDark.svg" alt="MySoft"/></Link>
<div className="remove">
<i className="bi bi-plus-lg"></i>
</div>
</div>
<ul>
<li className="has-child active">
<Link to="/saytlarin-hazirlanmasi">Saytların Hazırlanması</Link>
</li>
<li><Link to="/mobil-tetbiqler">Mobil Tətbiqlər</Link></li>
<li>
<Link to="/seo-xidmetleri">SEO Xidmətləri</Link>
</li>
<li>
<Link to="/server-texniki-destek">Server & Texniki Dəstək</Link>
</li>
<li>
<Link to="/IT-destek">IT dəstək</Link>
</li>
<li>
<Link to="/bloq">Bloq</Link>
</li>
</ul>
<div className="get-qoute d-flex justify-content-center d-lg-none d-block pt-50">
<div className="cmn-btn">
<div className="line-1"></div>
<div className="line-2"></div>
<Link to="tel:+994512070901">Əlaqə Saxla</Link>
</div>
</div>
</nav>
</div>
<div className="nav-right">
<div className="get-qoute">
<div className="cmn-btn">
<div className="line-1"></div>
<div className="line-2"></div>
<Link to="tel:+994512070901">Əlaqə Saxla</Link>
</div>
</div>
<div className="mobile-menu">
<span className="cross-btn">
<span className="cross-top"></span>
<span className="cross-middle"></span>
<span className="cross-bottom"></span>
</span>
</div>
</div>
</header>
  )
}

export default Navbar