import React, { useEffect } from "react";
import MainLayout from "../layout/MainLayout";
import { Link } from "react-router-dom";
import LatestBlog from "../components/LatestBlog";
import Order from "../components/Order";

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <MainLayout>
      <section className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb-wrapper">
                <div className="breadcrumb-cnt">
                  <h1>Haqqımızda</h1>
                  <span>
                    <Link to="/">MySoft</Link>
                    <i className="bi bi-arrow-right"></i>Haqqımızda
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="why-choose sec-mar wow animate fadeIn"
        data-wow-delay="200ms"
        data-wow-duration="1500ms"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="why-choose-left">
                <div className="choose-banner1">
                  <img src="assets/img/why-1.jpg" alt="ss" />
                </div>
                <div className="choose-banner2">
                  <img src="assets/img/why-2.jpg" alt="ss" />
                  <img src="assets/img/why-3.jpg" alt="ss" />
                </div>
                <div className="years">
                  <h5>20+</h5>
                  <span>İl Təcrübə</span>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="why-choose-right">
                <div className="sec-title layout2">
                  <span>Düzgün Seçim</span>
                  <h2>
                    MySoft İllərdir Ki, Düzgün Seçim Edən Müştərilər Üçün Xidmət
                    Edir.
                  </h2>
                </div>
                <div className="counter-boxes">
                  <div className="count-box">
                    <span className="counter">150</span>
                    <sup>+</sup>
                    <h5>Proyekt</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">250</span>
                    <sup>+</sup>
                    <h5>Məmnun Müştəri</h5>
                  </div>
                  <div className="count-box">
                    <span className="counter">50</span>
                    <sup>+</sup>
                    <h5>Ekspert Komanda</h5>
                  </div>
                </div>
                <p>
                  MySoft, uzun illər ərzində müştərilərinin tələblərinə və
                  gözləntilərinə düzgün cavab verməklə tanınan bir brenddir.
                  Müştərilərimizin seçimi ilə qürur duyur və onlara yüksək
                  keyfiyyətli xidmətlər təqdim etməyə əzəmət edirik. MySoft,
                  sadiq müştəri əlaqələri və innovativ yanaşma ilə fərqlənir, bu
                  da bizim müştərilərimizə sadəcə bir təchizatçı deyil, tərəfdaş
                  kimi yanaşmağımızın nəticəsidir. Şirkətimiz, müştərilərimiz
                  üçün ən yaxşı təcrübəni təmin etmək məqsədi ilə fəaliyyət
                  göstərir və onların bizə olan etibarını boşa çıxarmağaq üçün
                  çalışır. MySoft, müştərilərinin hər birini xüsusi və unikal
                  kimi qəbul edir və onlara təqdim etdiyi yenilikçi həllərlə
                  fərqlənir. Əgər siz də keyfiyyət və etibarlılığın təminatını
                  axtarırsınızsa, MySoft sizin üçün doğru seçimdir.
                </p>
                <div className="buttons-group">
                  <span>24/7 Dəstək</span>
                  <span>Unikal Həllər</span>
                  <span>Təhlükəsizlik Tədbirləri</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="about-area sec-mar-bottom wow animate slideInUp"
        data-wow-delay="200ms"
        data-wow-duration="1500ms"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 or-2">
              <div className="sec-title layout2">
                <span>Tanış olun</span>
                <h2>Biz kimik?</h2>
              </div>
              <div className="about-left">
                <p>
                  MySoft, müştəri-mərkəzlilik prinsipləri əsasında fəaliyyət
                  göstərən bir texnologiya şirkətidir. İllərlə müştəri təcrübəsi
                  və keyfiyyətli xidmətlər sayəsində şirkətimiz, inkişaf edən
                  texnologiya sahəsində lider konumunu qoruyur. Biz,
                  müştərilərimizə müstəsna və effektiv həllər təklif etməyə
                  fokuslanmış, sadiq və dinamik bir komandayıq. İnovativ yanaşma
                  və müştəri əlaqələrimizlə MySoft, müştərilərini digər
                  texnologiya şirkətlərindən fərqli təcrübələrlə tanış etmək
                  imkanı verir. Şirkətimiz, hər zaman müştəri gözləntilərini
                  üstün tutaraq, müştərilərimizin nailiyyətlərinə dəstək olmağı
                  əsas prinsip edinmişdir. MySoft, sizi texnologiya dünyasında
                  mövcud potensialınızın ən yaxşı şəkildə qazanmağa dəvət edir.
                </p>
                <div className="company-since">
                  <div className="company-logo">
                    <img src="assets/img/MySoftDark.svg" alt="ss" />
                  </div>
                  <h4>
                    IT Məsələləri Və Proqramlaşdırma Şirkəti
                    <span>2004-2024.</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 or-1">
              <div className="about-right">
                <div className="banner-1">
                  <img src="assets/img/about-baner-1.jpg" alt="ss" />
                </div>
                <div className="banner-2">
                  <img src="assets/img/about-baner-2.jpg" alt="ss" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Order/>
      <LatestBlog />
    </MainLayout>
  );
}

export default AboutUs;
